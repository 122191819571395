import * as React from 'react';
import classNames from 'clsx';
import { LinkProps } from '@wix/editor-elements-definitions';
import {
  customCssClasses,
  getDataAttributes,
  getTabIndexAttribute,
  replaceCompIdPlaceholder,
  useAnalyticsReportClicks,
} from '@wix/editor-elements-common-utils';
import {
  IImageXProps,
  ImageAttributesData,
  ResponsiveImageStyle,
} from '../ImageX.types';
import { TestIds } from '../constants';
import Link from '../../Link/viewer/Link';
import semanticClassNames from '../ImageX.semanticClassNames';
import style from './style/ImageX.scss';
import { getDisplayModeStyle } from './ImageX.utils';

const ResponsiveImage: React.FC<{ props: IImageXProps }> = ({ props }) => {
  const { sources, isInFirstFold, imageInfo, objectFit = 'cover' } = props;
  const defaultHeightAttribute = sources[sources.length - 1].heightAttribute;
  const defaultWidthAttribute = sources[sources.length - 1].widthAttribute;

  return (
    <picture data-testId={TestIds.pictureElement}>
      {sources!.map(
        ({ srcset, media, sizes, heightAttribute, widthAttribute }) => (
          <source
            srcSet={srcset}
            media={media}
            sizes={sizes}
            height={heightAttribute}
            width={widthAttribute}
          />
        ),
      )}

      <img
        src={imageInfo.imageData.uri}
        alt={imageInfo.imageData.alt}
        height={defaultHeightAttribute}
        width={defaultWidthAttribute}
        style={
          { '--responsive-img-object-fit': objectFit } as ResponsiveImageStyle
        }
        {...(isInFirstFold
          ? { fetchpriority: 'high', decoding: 'sync' }
          : { loading: 'lazy', decoding: 'async' })}
      />
    </picture>
  );
};

const getPictureSource = (
  sourceSets: IImageXProps['imageInfo']['sourceSets'],
  sourceSetPlaceholders?: Array<ImageAttributesData>,
) =>
  sourceSets.map((srcSet, i) => {
    const src = sourceSetPlaceholders?.[i]?.uri || undefined;
    return (
      <source key={i} media={srcSet.mediaQuery} srcSet={srcSet.src || src} />
    );
  });

const ImageWithPlaceholder: React.FC<{
  props: IImageXProps;
  hasSsrSrc: string;
  defaultPlaceholder?: ImageAttributesData;
  sourceSetPlaceholders?: Array<ImageAttributesData>;
}> = ({ props, hasSsrSrc, defaultPlaceholder, sourceSetPlaceholders }) => {
  const { id, imageInfo, defaultSrc } = props;

  const src = defaultPlaceholder?.uri || undefined;
  const placeholderStyle = defaultPlaceholder?.css?.img || {};
  // height is already handled - the fixed pixel size we get is messing up layout
  delete placeholderStyle.height;
  delete placeholderStyle.width;

  return (
    <wix-image
      id={`img-${id}`} // must for wix-image custom element
      data-image-info={JSON.stringify({ ...imageInfo, containerId: id })}
      data-has-ssr-src={hasSsrSrc}
    >
      <picture>
        {imageInfo.sourceSets &&
          getPictureSource(imageInfo.sourceSets, sourceSetPlaceholders)}
        <img
          src={src || defaultSrc}
          alt={imageInfo.imageData.alt}
          style={placeholderStyle}
        />
      </picture>
    </wix-image>
  );
};

const ScrollEffectLayer: React.FC<{
  containerId: string;
  pageId: string;
  hasScrollEffects: boolean;
  imageLayer: React.JSX.Element;
}> = ({ containerId, pageId, hasScrollEffects, imageLayer }) => {
  if (hasScrollEffects) {
    return (
      <wix-bg-media
        data-page-id={pageId}
        data-container-id={containerId}
        data-use-css-vars="true"
      >
        {imageLayer}
      </wix-bg-media>
    );
  }

  return imageLayer;
};

const LinkLayer: React.FC<{
  link: LinkProps | undefined;
  showLink: boolean;
  hasScrollEffects: boolean;
  imageLayer: React.JSX.Element;
  applyLinkFocusStyles: boolean;
}> = ({
  link,
  showLink,
  hasScrollEffects,
  imageLayer,
  applyLinkFocusStyles,
}) => {
  if (link && showLink) {
    return (
      <Link
        {...link}
        className={classNames({
          [style.linkWithEffect]: hasScrollEffects,
          [style.imageLink]: applyLinkFocusStyles,
        })}
      >
        {imageLayer}
      </Link>
    );
  }

  return imageLayer;
};

const PixelPerfectImage: React.FC<{
  props: IImageXProps;
  imagePlaceholderData: any;
}> = ({ props, imagePlaceholderData }) => {
  const { imageInfo, getPlaceholder, id, pageId, hasScrollEffects } = props;
  let hasSsrSrc = '';
  if (!imagePlaceholderData.current) {
    if (getPlaceholder) {
      hasSsrSrc = 'true';

      imagePlaceholderData.current = {
        defaultSrc: getPlaceholder({
          fittingType: imageInfo.imageData.displayMode || 'fill',
          src: {
            id: imageInfo.imageData.uri,
            width: imageInfo.imageData.width,
            height: imageInfo.imageData.height,
            crop: imageInfo.imageData.crop,
            name: imageInfo.imageData.name,
            focalPoint: imageInfo.imageData.focalPoint,
          },
          target: {
            alignment: imageInfo.alignType,
            htmlTag: 'img',
          },
        }),
        sourceSet: imageInfo.sourceSets?.map(imageData =>
          getPlaceholder({
            fittingType: imageData.displayMode,
            src: {
              id: imageInfo.imageData.uri,
              width: imageInfo.imageData.width,
              height: imageInfo.imageData.height,
              crop: imageData.crop,
              name: imageInfo.imageData.name,
              focalPoint: imageData.focalPoint,
            },
            target: {
              alignment: imageInfo.alignType,
              htmlTag: 'img',
            },
          }),
        ),
      };
    } else {
      // to keep an empty placeholder data
      imagePlaceholderData.current = {
        defaultSrc: {
          uri: '',
          css: { img: {}, container: {} },
          attr: { img: {}, container: {} },
        },
        sourceSet: [],
      };
    }
  }
  const defaultPlaceholder = imagePlaceholderData.current?.defaultSrc;
  const sourceSetPlaceholders = imagePlaceholderData.current?.sourceSet;

  const imageLayer = (
    <ImageWithPlaceholder
      props={props}
      hasSsrSrc={hasSsrSrc}
      defaultPlaceholder={defaultPlaceholder}
      sourceSetPlaceholders={sourceSetPlaceholders}
    />
  );

  return (
    <ScrollEffectLayer
      containerId={id}
      pageId={pageId}
      hasScrollEffects={hasScrollEffects}
      imageLayer={imageLayer}
    />
  );
};

const ImageX: React.FC<IImageXProps> = (props: IImageXProps) => {
  const {
    id,
    className,
    customClassNames = [],
    link,
    skin,
    showLink,
    imageInfo,
    aspectRatio,
    useNativeAspectRatio,
    hasScrollEffects,
    applyLinkFocusStyles,
    scrollEffectStyles,
    onClick,
    onDblClick,
    onMouseEnter,
    onMouseLeave,
    reportBiOnClick,
    shouldUseResponsiveImages,
    sources,
  } = props;

  const imagePlaceholderData = React.useRef<{
    defaultSrc: ImageAttributesData;
    sourceSet: Array<ImageAttributesData>;
  } | null>(null);

  let imageLayer = null;

  if (shouldUseResponsiveImages && sources?.length > 0) {
    imageLayer = <ResponsiveImage props={props} />;
  } else {
    imageLayer = (
      <PixelPerfectImage
        props={props}
        imagePlaceholderData={imagePlaceholderData}
      />
    );
  }

  const displayModeStyles = getDisplayModeStyle(
    imageInfo.imageData.displayMode,
    aspectRatio,
    useNativeAspectRatio,
  );

  const handleClick = useAnalyticsReportClicks({
    onClick,
    reportBiOnClick,
  });

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      {...getTabIndexAttribute(props.a11y)}
      data-testid={TestIds.root}
      className={classNames(
        style[skin],
        className,
        shouldUseResponsiveImages && style.responsiveImg,
        customCssClasses(semanticClassNames.root, ...customClassNames),
      )}
      onClick={handleClick}
      onDoubleClick={onDblClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <style data-testid={TestIds.scrollEffectStyle}>
        {scrollEffectStyles && replaceCompIdPlaceholder(scrollEffectStyles, id)}
      </style>
      {displayModeStyles && (
        <style data-testid={TestIds.displayModeStyle}>
          {replaceCompIdPlaceholder(displayModeStyles, id)}
        </style>
      )}

      <LinkLayer
        link={link}
        showLink={showLink}
        hasScrollEffects={hasScrollEffects}
        imageLayer={imageLayer}
        applyLinkFocusStyles={applyLinkFocusStyles}
      />
    </div>
  );
};

export default ImageX;
